import './APropos.css'; 
import HugoBoulet from '../assets/Hugo_Boulet-removebg-preview.png';
import AdrienMoncet from '../assets/Adrien_Moncet-removebg-preview.png';
import BaptisteFerahian from '../assets/Baptise_Ferahian-removebg-preview.png';
import nosValeur1 from '../assets/nosValeur1.png';
import nosValeur2 from '../assets/nosValeur2.png';
import nosValeur3 from '../assets/nosValeur3.png';
import nosValeur4 from '../assets/nosValeur4.png';

function APropos() {

  return (
    <div className="background">
      <div className={`apropos-container }`}>
        <h1>À Propos</h1>
        <section className="about-section">
          <p className='subtitle-section'>
            Bienvenue chez Edufin, votre partenaire de confiance pour maîtriser l'art de l'investissement tout en restant
            connecté aux dernières tendances financières.
          </p>
          <div className="images-section">
          <div className="image-container">
            <img src={nosValeur1} alt="Excellence" className="value-image1" />
            <p className="image-subtitle">Une priorité pour nous pour vous offrir la meilleure des qualités</p>
          </div>
          <div className="image-container">
            <img src={nosValeur2} alt="Accessibilité" className="value-image" />
            <p className="image-subtitle">Démocratisons la finance ensemble et pour tous</p>
          </div>
          <div className="image-container">
            <img src={nosValeur3} alt="Communauté" className="value-image" />
            <p className="image-subtitle">Rejoignez une communauté aussi patronnée que vous</p>
          </div>
          <div className="image-container">
            <img src={nosValeur4} alt="Transparence" className="value-image" />
            <p className="image-subtitle">Faisons-nous confiance et évoluons ensemble</p>
          </div>
          </div>
        </section>

        <section className="founders-section">
          <h2>Nos Fondateurs</h2>
          <p className='subtitle-section'>
            Nos fondateurs, trois experts passionnés, ont uni leurs forces pour créer Edufin, une plateforme où 
            l'apprentissage rencontre l'information.
          </p>
          <div className="founders">
            <div className="founder">
              <img src={HugoBoulet} alt="Hugo Boulet" />
              <h3>Hugo Boulet</h3>
              <p>Ancien sportif de haut niveau, a transposé sa rigueur du terrain à la bourse, obtenant des certifications 
              et accumulant une solide expérience en finance de marché.</p>
            </div>
            <div className="founder">
              <img src={BaptisteFerahian} alt="Baptiste Ferahian" />
              <h3>Baptiste Ferahian</h3>
              <p>Autodidacte aguerri du Web3, excelle dans le domaine des cryptomonnaies et oriente ses compétences 
              vers l'avenir financier.</p>
            </div>
            <div className="founder">
              <img src={AdrienMoncet} alt="Adrien Moncet" />
              <h3>Adrien Moncet</h3>
              <p>Etudiant en ingénierie web/cybersécurité et sportif de haut niveau, a créé une plateforme intuitive, rendant les outils financiers 
              avancés accessibles à tous.</p>
            </div>
          </div>
        </section>
      </div>
      </div>
    );
  }

export default APropos;
