import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <section className="hero">
      <div className="textContainer">
        <h1 className="heading">L'investissement à votre portée !</h1>
        <p className="paragraph">
          Notre mission, vous informer en temps réel et vous former pour devenir un investisseur aguerri
        </p>
        <div className="buttonContainer">
          <button onClick={() => handleRedirect('/académie')} className="primaryButton">Débuter maintenant</button>
          <button onClick={() => handleRedirect('/apropos')} className="secondaryButton">Découvrir plus</button>
        </div>
      </div>
      <div className="visual">
        <iframe 
          className="iframe" 
          src="https://www.youtube.com/embed/q9RNq12GWq8" 
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default HeroSection;