import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cryptoIcon from '../assets/cryptoIcon.png';
import bourseIcon from '../assets/bourseIcon.png';
import guideIcon from '../assets/guideIcon.png';
import newsletterIcon from '../assets/newsletterIcon.png';
import { FaGlobe } from 'react-icons/fa'; // Importez l'icône de recherche
import logo from '../assets/edufin.png';
import './Navbar.css';

const Navbar = () => {
  const [showInvestDropdown, setShowInvestDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // État pour la requête de recherche
  const [isBurgerOpen, setIsBurgerOpen] = useState(false); // État pour le menu burger

  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollTo = (sectionId) => {
    if (location.pathname !== '/actualités') {
      navigate('/actualités', { state: { scrollToId: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setShowInvestDropdown(false);
    setIsBurgerOpen(false); // Ferme le menu burger après la navigation
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Empêche la soumission par défaut
    if (searchQuery.trim()) { // Vérifie que la recherche n'est pas vide
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Surveille la direction du défilement et montre/cache la navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false); // Cache la navbar si on défile vers le bas
      } else {
        setIsVisible(true); // Montre la navbar si on défile vers le haut
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  // Fermer les dropdowns si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar__dropdown-container') && !event.target.closest('.navbar__user-section')) {
        setShowInvestDropdown(false);
        setShowUserDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="main-content">
        {showInvestDropdown && <div className="navbar__overlay"></div>}
        {showUserDropdown && <div className="navbar__overlay"></div>}

        <nav className={`navbar ${isVisible ? 'navbar--visible' : 'navbar--hidden'}`}>
          <div className="navbar-mobile__left-section">
            <div className="burger-menu" onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
              <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
              <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
              <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
            </div>
          </div>

          <div className="navbar__logo">
            <Link to="/">
              <img src={logo} alt="Edufin" className="navbar__logo-image" />
            </Link>
          </div>

          <ul className={`navbar__nav-links ${isBurgerOpen ? 'navbar__nav-links--open' : ''}`}>
            <li><Link to="/académie"><button className="navbar__li-button">Académie</button></Link></li>
            <li
              className="navbar__dropdown-container"
              onMouseEnter={() => setShowInvestDropdown(true)}
              onMouseLeave={(event) => {
                const dropdownMenu = document.querySelector('.navbar__dropdown-menu');
                const rect = dropdownMenu.getBoundingClientRect();
                if (event.clientY > rect.bottom) setShowInvestDropdown(false);
              }}
            >
              <Link to="/actualités">
                <button className="navbar__li-button">
                  Actualités 
                  <span className={`navbar__arrow ${showInvestDropdown ? 'navbar__arrow--up' : ''}`}>&#9660;</span>
                </button>
              </Link>
              {showInvestDropdown && (
                <ul className="navbar__dropdown-menu">
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('crypto')}>
                    <img src={cryptoIcon} alt="Cryptomonnaies" className="navbar__icon" /> Cryptomonnaies
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('bourse')}>
                    <img src={bourseIcon} alt="Bourse" className="navbar__icon" /> Bourse
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('guide')}>
                    <img src={guideIcon} alt="Guides" className="navbar__icon" /> Guides
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('newsletter')}>
                    <img src={newsletterIcon} alt="Newsletter" className="navbar__icon" /> Newsletter
                  </li>
                </ul>
              )}
            </li>

            <li><Link to="/apropos"><button className="navbar__li-button">À Propos</button></Link></li>
            <li><Link to="/contact"><button className="navbar__li-button">Contact</button></Link></li>
          </ul>
          <div className="navbar__right-section">
            <Link to="/académie">
              <button className="navbar__signup-button">Inscription</button>
            </Link>
            <button className="navbar__language-button">
              <FaGlobe /> FR/FR
            </button>
            <form onSubmit={handleSearchSubmit} className="navbar__search-form">
              <input
                type="text"
                placeholder="Rechercher..."
                className="navbar__search-bar"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </form>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;