import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import {UserProvider} from './context/UserContext';
import Navbar from './components/Navbar';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Académie from './pages/Académie2';
import Actualités from './pages/Actualités';
import APropos from './pages/APropos';
import SearchResults from './components/SearchResults';
import SearchDropdown from './components/SearchDropdown';
import ArticlePage from './Articles/ArticlePage';
import ScrollToTop from './components/ScrollToTop'; // Importez le composant ScrollToTop

function App() {
  const [showNavbar, setShowNavbar] = useState(true);

  const handleHideNavbar = () => {
    setShowNavbar(false);
  };

  return (
    <UserProvider>
      <Router>
        <ScrollToTop /> {/* Ajoutez le composant ScrollToTop */}
        {showNavbar && <Navbar />}
        <MainContent handleHideNavbar={handleHideNavbar} />
      </Router>
    </UserProvider>
  );
}

const MainContent = ({ handleHideNavbar }) => {
  const location = useLocation();
  const isArticlePage = location.pathname.startsWith('/articles');

  return (
    <div className={`main-content ${isArticlePage ? 'no-padding' : ''}`}>
      <Routes>
        <Route path="/signup" element={<Signup onButtonClick={handleHideNavbar} />} />
        <Route path="/login" element={<Login onButtonClick={handleHideNavbar} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/académie" element={<Académie />} />
        <Route path="/actualités" element={<Actualités />} />
        <Route path="/apropos" element={<APropos />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/searchDropdown" element={<SearchDropdown />} />
        <Route path="/articles/:slug" element={<ArticlePage />} />
      </Routes>
    </div>
  );
};

export default App;