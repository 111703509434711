import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cryptoImage from '../assets/dollar-crypto.webp';
import bourseImage from '../assets/BourseImage.jpg';
import background from '../assets/Wallpaper.png';
import TemasekImage from '../assets/temasek-republic-blockchain1.jpg';
import HausseBitcoinImage1 from '../assets/hausse-bitcoin-renouveau-ou-mirage1.jpg';
import CoinbaseImage1 from '../assets/coinbase-chute-wallstreet1.jpg';
import UkrenergoImage from '../assets/ukraine-ukrenergo-suspend-paiements.png'; // Importez l'image pour l'article 4
import TrumpMarketImage from '../assets/stocks-hausse-obligations-baisse1.png'; // Importez l'image pour l'article 5
import BoeingImage from '../assets/boeing-greve-accord-salarial1.png'; // Importez l'image pour l'article 6

const Actualités = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const cards = document.querySelectorAll('.guide-card, .news-card, .ressources-card');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    cards.forEach(card => {
      observer.observe(card);
    });

    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);

    const handleRedirect = () => {
      navigate('/académie');
      window.scrollTo(0, 0);
    };

    const handleMouseEnter = (index) => {
      setHoveredCard(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredCard(null);
    };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        {/* En-tête */}
        <header style={styles.header}>
          <h1 style={styles.title}>Maîtrisez l'investissement avec Edufin</h1>
          <p style={styles.subtitle}>
            Découvrez nos guides et actualités pour maîtriser les investissements en cryptomonnaie et en bourse
          </p>
          <button style={styles.primaryButton} onClick={handleRedirect}>
          Commencez dès maintenant
          </button>
        </header>

        {/* Actualités Crypto */}
        <section id="crypto" style={styles.latestNewsSection}>
          <h2 style={styles.sectionTitle}>Dernières Actualités Cryptomonnaies</h2>
            <div style={styles.newsGrid}>
              {/* News 1 */}
              <Link to="/articles/coinbase-chute-wallstreet" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 2 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={CoinbaseImage1} alt="Coinbase chute après Wall Street" style={styles.image} />
                <p style={styles.newsMeta}>11 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>Coinbase chute après des résultats inférieurs aux attentes de Wall Street</h3>
              </Link>
            <div/>
            {/* News 2 */}
            <Link to="/articles/hausse-bitcoin-renouveau-ou-mirage" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 1 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={HausseBitcoinImage1} alt="Hausse du Bitcoin" style={styles.image} />
                <p style={styles.newsMeta}>10 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>Hausse du Bitcoin : Vers un renouveau ou simple mirage pour les cryptomonnaies ?</h3>
              </Link>
            {/* News 3 */}
            <Link to="/articles/temasek-republic-blockchain" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 0 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(0)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={TemasekImage} alt="Temasek et Republic s'associent" style={styles.image} />
                <p style={styles.newsMeta}>6 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>100 millions pour transformer la blockchain : Temasek et Republic s'associent</h3>
              </Link>
          </div>
          <div style={styles.buttonContainer}>
            <Link to="/searchDropdown" style={styles.secondaryButton}>Voir plus d'articles</Link>
          </div>
        </section>

        {/* Actualités Bourse */}
        <section id="bourse" style={styles.latestNewsSection}>
          <h2 style={styles.sectionTitle}>Dernières Actualités Boursières</h2>
          <div style={styles.newsGrid}>
            {/* News 1 */}
            <Link to="/articles/ukraine-ukrenergo-suspend-paiements" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 3 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={UkrenergoImage} alt="Ukrenergo suspend ses paiments" style={styles.image} />
                <p style={styles.newsMeta}>11 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>L'Ukraine : Ukrenergo suspend les paiements de sa dette verte de 825 millions de dollars en attente de restructuration</h3>
              </Link>
            {/* News 2 */}
            <Link to="/articles/stocks-hausse-obligations-baisse" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 4 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={TrumpMarketImage} alt="les marchés financiers réagissent à la réélection de Donald Trump" style={styles.image} />
                <p style={styles.newsMeta}>5 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>Stocks en Hausse, Obligations en Baisse : Réaction des Marchés après la Victoire de Trump</h3>
              </Link>
            {/* News 3 */}
            <Link to="/articles/boeing-greve-accord-salarial" 
                className="guide-card" // Utilisez les classes existantes pour garder le style
                style={{ ...styles.newsLink, ...(hoveredCard === 5 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard) }}
                onMouseEnter={() => handleMouseEnter(5)}
                onMouseLeave={handleMouseLeave}
              >
                <img src={BoeingImage} alt="Boeing a proposé une augmentation salariale aux employés grévistes" style={styles.image} />
                <p style={styles.newsMeta}>3 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
                <h3 style={styles.newsTitle}>Grève chez Boeing : Vers un Accord Salarial Historique</h3>
              </Link>
          </div>
          <div style={styles.buttonContainer}>
            <Link to="/searchDropdown" style={styles.secondaryButton}>Voir plus d'articles</Link>
          </div>
        </section>

        {/* Section Guide */}
        <section id="guide" style={styles.guidesSection}>
          <h2 style={styles.sectionTitle}>Guides pour débutants</h2>
          <div style={styles.guidesGrid}>
            {/* Guide 1 */}
            <a
              href="/Guide_Complet-Cryptomonnaies.pdf"
              download="Guide_Complet-Cryptomonnaies.pdf"
              className="guide-card no-decoration"
              style={hoveredCard === 6 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard}
              onMouseEnter={() => handleMouseEnter(6)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={cryptoImage} alt="Guide 1" style={styles.image} />
              <h3 className="guide-title" style={styles.guideTitle}>Guide cryptomonnaies</h3>
            </a>
            {/* Guide 2 */}
            <div
              className="guide-card"
              style={hoveredCard === 7 ? { ...styles.guideCard, ...styles.guideCardHover } : styles.guideCard}
              onMouseEnter={() => handleMouseEnter(7)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={bourseImage} alt="Guide 2" style={styles.image} />
              <h3 className="guide-title" style={styles.guideTitle}>Guide bourse</h3>
            </div>
          </div>
        </section>
          {/* Section Newsletters */}
        <section id="newsletter" style={styles.newsletterSection}>
          <header style={styles.header2}>
            <h2 style={styles.title}>Abonnez-vous à notre Newsletter !</h2>
            <p style={styles.subtitle2}>
              Recevez les dernières actualités et analyses des marchés financiers directement dans votre boîte mail.          </p>
              <a href="https://ca841cc5.sibforms.com/serve/MUIFAPuSy5Nx0Vchq3EVduGDudsQrDBzu6XkbzgXC8GgaT0W65N8nmNxWDgJvBSFf-DLKmSOmfmabcYxv7mMfkALgE7p6iFoa067WChDx3WqjXln2E3854fisQ0Yy6LT6O5j-NVBlFF-nDUVGRWrMHSLD6FZzLigJdKQlpgXh0Dqn1f2OVhFEQw9RTjtmf-bWePuPgLlxKqJ1z8x" style={styles.primaryButton}>
                S'abonner
              </a>
          </header>
        </section>
      </div>
    </div>
  );
}

const styles = {
  background: {
    background: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    minHeight: '390vh',
  },
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  header: {
    padding: '60px 20px',
    textAlign: 'center',
    marginBottom: '0px',
    marginTop: '20px',
  },
  header2: {
    padding: '60px 20px',
    textAlign: 'center',
    marginBottom: '0px',
    marginTop: '0px',
  },
  title: {
    fontSize: '56px',
    fontWeight: '700',
    color: '#f5f5f5',
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '19px',
    color: '#f5f5f5',
    marginBottom: '30px',
  },
  primaryButton: {
    backgroundColor: '#243EFF',
    color: 'white',
    padding: '15px 30px',
    border: 'none',
    borderRadius: '50px',
    fontSize: '1.2em',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  guidesSection: {
    padding: '60px 20px',
    marginTop: '-40px',
    },
  sectionTitle: {
    fontSize: '38px',
    fontWeight: '600',
    marginBottom: '40px',
    textAlign: 'center',
    color: '#f5f5f5',
  },
  guidesGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  guideCard: {
    width: '300px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Ajout de la transition pour l'effet de survol
  },
  guideCardHover: {
    transform: 'translateY(-10px)', // Déplace légèrement vers le haut
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)', // Augmente l'ombre
  },
  guideTitle: {
    fontSize: '19px',
    fontWeight: '600',
  },
  latestNewsSection: {
    padding: '60px 20px',
    marginTop: '-40px',
  },
  newsGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  newsCard: {
    width: '300px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#fff',
  },
  newsTitle: {
    fontSize: '19px',
    fontWeight: '600',
    textDecoration: 'none',
  },
  newsLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  newsMeta: {
    fontSize: '14px',
    color: '#888',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '80px',
  },
  secondaryButton: {
    backgroundColor: 'white',
    color: '#243EFF',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '50px',
    fontSize: '1em',
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: '0px',
  },
  image: {
    width: '300px', // Ajustez cette valeur selon vos besoins
    height: '175px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  newsletterContainer: {
    marginTop: '60px',
    textAlign: 'center',
  },
  subtitle2: {
    fontSize: '19px',
    color: '#f5f5f5',
    marginBottom: '40px',
  },
};

export default Actualités;
