import React from 'react';

const HeroSection = () => {

  return (
    <section style={styles.hero}>
      <div style={styles.textContainer}>
        <h1 style={styles.heading}>Rejoignez l'Académie Edufin !</h1>
        <p style={styles.paragraph}>
          Préinscrivez-vous <strong style={styles.bold}>dès maintenant</strong> pour accéder à des cours exclusifs et à des ressources pédagogiques de qualité. Que vous soyez débutant ou expert, l'Académie Edufin vous accompagne dans votre apprentissage financier.
        </p>
        <div style={styles.buttonContainer}>
          <a href="https://edufin.podia.com/" style={styles.primaryButton} target="_blank" rel="noopener noreferrer">
            Préinscrivez-vous
          </a>
        </div>
      </div>
      <div style={styles.visual}>
        <iframe 
          style={styles.iframe} 
          src="https://playplay.com/app/embed-video/c166d0d6-d526-458c-921e-a6f41530bf3f?autoplay=true&loop=1" 
          title="Rejoignez l'Académie Edufin !" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

const styles = {
  hero: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    overflow: 'hidden',
    minHeight: '85vh',
  },
  textContainer: {
    maxWidth: '800px',
    textAlign: 'center',
    marginRight: '0px',
    color: 'black',
  },
  heading: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#f5f5f5',
  },
  paragraph: {
    fontFamily: 'Raleway, sans-serif',
    color: '#999',
    fontSize: '19px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  primaryButton: {
    backgroundColor: '#243EFF',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '50px',
    fontSize: '1.2em',
    cursor: 'pointer',
    textDecoration: 'none',
  },

  visual: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0px',
  },
  iframe: {
    width: '700px', // Ajuste la taille pour la vidéo
    height: '394px', // Ratio 16:9 pour la vidéo
    borderRadius: '17px',
  },
  bold: {
    fontWeight: 'bold',
    color: '#f0f0f0',
  },
};

export default HeroSection;
