import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { articleData } from '../Articles/ArticlePage'; // Importez les données des articles
import ArticleCard from '../Articles/ArticleCard'; // Importez le composant ArticleCard
import './SearchResults.css';

// Convertir les données des articles en un tableau
const articles = Object.keys(articleData).map(key => ({
  id: key,
  title: articleData[key].title,
  date: new Date(articleData[key].date), // Convertir la date en objet Date
  author: articleData[key].author,
  content1: articleData[key].content1, // Assurez-vous d'utiliser la bonne clé pour le contenu
  imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
  link: `/articles/${key}`
}));

// Trier les articles par date (du plus récent au plus ancien)
articles.sort((a, b) => b.date - a.date);

const SearchDropdown = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || '';
    setSearchTerm(query);

    const filteredResults = articles.filter(article =>
      article.title.toLowerCase().includes(query.toLowerCase()) ||
      article.content1.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredArticles(filteredResults);
  }, [location.search]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredArticles(
      articles.filter(article =>
        article.title.toLowerCase().includes(term) || article.content1.toLowerCase().includes(term)
      )
    );
  };

  return (
    <div className="background">
      <div className="search-dropdown">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="search-results">
          {filteredArticles.length > 0 ? (
            filteredArticles.map(article => (
              <ArticleCard
                key={article.id}
                title={article.title}
                date={article.date instanceof Date ? article.date.toLocaleDateString() : article.date} // Convertir la date en chaîne de caractères
                author={article.author}
                imageUrl={article.imageUrl}
                content1={article.content1}
                link={article.link}
              />
            ))
          ) : (
            <p className='error'>Aucun résultat trouvé</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;