import React from 'react';
import background from '../assets/Wallpaper.png';
import HeroSection from '../components/HeroSection2';

function Académie2() {
  return (
    <div style={styles.page}>
      <div style={styles.container}>
      <HeroSection />
      </div>
    </div>
  );
}
 
const styles = {
  page: {
    position: 'relative',
    background: `url(${background}) no-repeat center center/cover`,
    backgroundAttachment: 'fixed',  // Le background reste fixe au scroll
    textAlign: 'center',
    minHeight: '100vh',  // S'assure que ça couvre au moins la taille de l'écran
  },
  content: {
    position: 'relative',
    minHeight: '100vh',  // Ajoute cette propriété pour que le contenu s'étende sur toute la page
    paddingBottom: '50px',  // Ajoute un espace en bas pour un meilleur espacement visuel
    marginTop: '70px',  // Ajoute un espace en haut pour éviter que le contenu ne soit trop proche du haut de la
  },
};

export default Académie2;