import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import "./ArticleTemplate.css";
import InstagramLogo from '../assets/Instagram.png'; // Importez le logo Instagram
import LinkedInLogo from '../assets/Linkedin.png'; // Importez le logo LinkedIn
import TiktokLogo from '../assets/Tiktok.png'; // Importez le logo TikTok
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { FaShareAlt } from 'react-icons/fa'; // Importez l'icône de partage
import TrumpMarketImage from '../assets/stocks-hausse-obligations-baisse1.png'; // Importez l'image pour l'article 5
import HausseBitcoinImage1 from '../assets/hausse-bitcoin-renouveau-ou-mirage1.jpg';
import UkrenergoImage from '../assets/ukraine-ukrenergo-suspend-paiements.png'; // Importez l'image pour l'article 4

const ArticleTemplate = ({ title, date, author, content1, imageUrl1, credit1, content2, imageUrl2, credit2 }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const sidebarRef = useRef(null);
  const articleRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const article = articleRef.current;
      if (!sidebar || !article) return; // Ajoutez cette vérification
      const articleBottom = article.getBoundingClientRect().bottom;
      const sidebarHeight = sidebar.offsetHeight;

      if (articleBottom <= sidebarHeight) {
        sidebar.style.position = 'absolute';
        sidebar.style.top = `${articleBottom - sidebarHeight}px`;
      } else {
        sidebar.style.position = 'sticky';
        sidebar.style.top = '120px';
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const shareUrl = `${window.location.origin}${location.pathname}`; // URL de la page actuelle

  return (
    <div className="article-template-background">
      <div className="article-template-wrapper">
        <div className="article-template-container">
          <header className="article-template-header">
            <h1 className="article-template-title">{title}</h1>
            <div className="article-template-meta">
              <span className="article-template-date">{date}</span>
              <span className="article-template-separator">|</span>
              <span className="article-template-author">By {author}</span>
            </div>
            <div className="article-template-content" dangerouslySetInnerHTML={{ __html: content1 }}></div>
          </header>
          <div className="article-template-body">
            {imageUrl1 && (
              <>
                <img src={imageUrl1} alt={title} className="article-template-image" />
                <p className="image-template-credit">{credit1}</p>
              </>
            )}
            <div className="article-template-content" dangerouslySetInnerHTML={{ __html: content2 }}></div>
            {imageUrl2 && (
              <>
                <img src={imageUrl2} alt={title} className="article-template-image" />
                <p className="image-template-credit">{credit2}</p>
              </>
            )}
          </div>
        </div>
        <div className="sidebar" ref={sidebarRef}>
          <aside className="most-read-container">
            <h2 className="most-read-title">Top News</h2>
            <ul className="most-read-list">
              <li className="most-read-item">
                <Link to="/articles/stocks-hausse-obligations-baisse">
                <div className="most-read-content">
                  <img src={TrumpMarketImage} alt="Stocks en Hausse, Obligations en Baisse" className="most-read-image" />
                  <p className="most-read-link">Stocks en Hausse, Obligations en Baisse : Réaction des Marchés après la Victoire de Trump</p>
                </div>
                </Link>
              </li>
              <li className="most-read-item">
                <Link to="/articles/ukraine-ukrenergo-suspend-paiements">
                <div className="most-read-content">
                  <img src={UkrenergoImage} alt="Ukrenergo suspend ses paiements" className="most-read-image" />
                  <p className="most-read-link">L'Ukraine : Ukrenergo suspend les paiements de sa dette verte de 825 millions de dollars en attente de restructuration</p>
                </div>
                </Link>
              </li>
              <li className="most-read-item">
                <Link to="/articles/hausse-bitcoin-renouveau-ou-mirage">
                <div className="most-read-content">
                  <img src={HausseBitcoinImage1} alt="Hausse du Bitcoin" className="most-read-image" />
                  <p className="most-read-link">Hausse du Bitcoin : Vers un renouveau ou simple mirage pour les cryptomonnaies ?</p>
                </div>
                </Link>
              </li>
            </ul>
          </aside>
          <aside className="social-network">
            <h2 className="social-network-title">Suivez-nous et partagez l'article</h2>
            <ul className="social-network-list">
              <li className="social-network-item">
                <a href="https://www.instagram.com/edufin.off/" className="social-network-link">
                  <img src={InstagramLogo} alt="Instagram" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <a href="https://linkedin.com" className="social-network-link">
                  <img src={LinkedInLogo} alt="LinkedIn" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <a href="https://www.tiktok.com/@edufin.off?is_from_webapp=1&sender_device=pc" className="social-network-link">
                  <img src={TiktokLogo} alt="TikTok" className="social-network-logo" />
                </a>
              </li>
              <li className="social-network-item">
                <button className="social-network-link" onClick={() => setShowShareOptions(!showShareOptions)}>
                  <FaShareAlt className="social-network-logo"/>
                  <span className={`navbar__arrow ${showShareOptions ? 'navbar__arrow--up' : ''}`}>&#9660;</span>
                </button>
                {showShareOptions && (
                  <div className="share-options">
                    <FacebookShareButton url={shareUrl} className="share-option">
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} className="share-option">
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl} className="share-option">
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <WhatsappShareButton url={shareUrl} className="share-option">
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </div>
                )}
              </li>
            </ul>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ArticleTemplate;