import './Contact.css';

const Contact = () => {

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h1>Contactez-nous</h1>
        <p>
          Vous avez des questions, des suggestions ou besoin d'assistance ? Nous sommes là pour vous aider ! 
          N'hésitez pas à nous contacter et nous vous répondrons dans les plus brefs délais. Votre satisfaction est notre priorité.
        </p>
        <a href="mailto:edufin.educ@gmail.com" className="mailto-btn">Envoyer un email</a>
      </div>
    </div>
  );
}
export default Contact;